import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import LayoutMain from "../components/layout-main";
import SEO from "../components/seo";

const BlogIndex = ({ data, location, pageContext: { apiPosts } }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
    return (
      <LayoutMain location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </LayoutMain>
    );


};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
